<template>
  <div>
    <!--begin::customer-->
    <b-card class="mb-5" no-body>
      <b-card-header class="p-1" header-tag="header" role="tab">
        <b-button v-b-toggle.accordion-2 block class="text-left font-weight-bold text-dark pl-8 pr-8 fs-102 collapsed-with-icon"
                  variant="link">
          {{ $t('advanced_filter') }}
        </b-button>
      </b-card-header>
      <b-collapse id="accordion-2" accordion="my-accordion" role="tabpanel" visible>
        <b-card-body>
          <div class="m-form m-form--fit m--margin-bottom-20">
            <div class="row mb-5">

              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="customers">{{ $t('payments_sales_report.customer') }}</label>
                <!-- <select name="" id="customers" v-model="filters.customers" class="custom-select">
                    <option v-for="row in customers" :value="row.id" :key="'customer'+row.id">
                        {{ row.fullname }}
                    </option>
                </select> -->
                <multiselect v-model="customer"
                             :multiple="true"
                             :options="customers"
                             :placeholder="$t('payments_sales_report.customer')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="fullname"
                             track-by="id"
                             @input="setCustomers"
                             @search-change="getCustomers($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.branches') }}</label>
                <multiselect v-model="branches_ids"
                             :placeholder="$t('customer_transactions_report.branches')"
                             label="name"
                             track-by="id"
                             :options="branches"
                             :multiple="true"
                             @input="getIds('branches')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="inventories">{{$t('bonds_and_payments_details_report.inventories')}}</label>
                <multiselect v-model="inventories_ids"
                             id="inventories"
                             :placeholder="$t('bonds_and_payments_details_report.inventories')"
                             label="name"
                             track-by="id"
                             :options="inventories"
                             :multiple="true"
                             @input="getIds('inventories')"
                             :taggable="false"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false">
                </multiselect>
              </div>


              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="staff">{{ $t('staff') }}</label>
                <multiselect id="staff" v-model="employee"
                             :multiple="false"
                             :options="employees" :placeholder="$t('employee')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="full_name"
                             track-by="id"
                             @search-change="getEmployees($event)">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('filter_by') }}</label>
                <select name="" id="invoice_type" v-model="filters.filter_by" class="custom-select">
                  <option value="payments">{{$t('bonds_and_payments_details_report.payments')}}</option>
                  <option value="incomes">{{$t('bonds_and_payments_details_report.incomes')}}</option>
                  <option value="expenses">{{$t('bonds_and_payments_details_report.expenses')}}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('customer_transactions_report.invoice_type') }}</label>
                <select name="" id="invoice_type" v-model="filters.invoice_type" class="custom-select">
                  <option v-for="row in invoice_type_list" :disabled="row.disabled" :value="row.key" :key="row.key">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label for="code">{{ $t('customer_transactions_report.code') }}</label>
                <input v-model="filters.code" type="text" id="code" class="form-control">
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.added_by') }}</label>
                <!-- <select name="" id="added_by" v-model="filters.user_id" class="custom-select">
                    <option v-for="row in users" :value="row.id" :key="'by'+row.id">
                        {{ row.name }}
                    </option>
                </select> -->
                <multiselect v-model="user"
                             :multiple="false"
                             :options="users"
                             :placeholder="$t('payments_sales_report.added_by')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.payment_method') }}</label>
                <select id="payment_method" v-model="filters.payment_method" class="custom-select" name="">
                  <option v-for="row in payment_method_list" :key="row.id" :value="row.id">{{ row.title }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('insurance_agents.classes') }}</label>
                <select id="payment_method" v-model="filters.class" class="custom-select" name="">
                  <option v-for="row in class_list" :key="row.key" :value="row.key">{{ row.name }}</option>
                </select>
              </div>
              <div class="form-group col-md-6 mt-2 mb-2">
                <label>{{ $t('payments_sales_report.collected_by') }}</label>
                <!-- <select name="" id="collected_user_id" v-model="filters.collected_user_id" class="custom-select">
                    <option v-for="row in users" :value="row.id" :key="row.id">
                        {{ row.name }}
                    </option>
                </select> -->
                <multiselect v-model="collected_by"
                             :multiple="false"
                             :options="users"
                             :placeholder="$t('payments_sales_report.collected_by')"
                             :show-labels="false"
                             :show-no-options="false"
                             :show-no-results="false"
                             :taggable="false"
                             label="name"
                             track-by="id">
                </multiselect>
              </div>
              <div class="form-group col-md-3 mt-2 mb-2">
                <label for="from_date">{{ $t('payments_sales_report.from_date') }}</label>
                <input id="from_date" v-model="filters.from_date" class="form-control" type="date">
              </div>
              <div class="form-group col-md-3 mt-2 mb-2">
                <label for="to_date">{{ $t('payments_sales_report.to_date') }}</label>
                <input id="to_date" v-model="filters.to_date" class="form-control" type="date">
              </div>
<!--              <div class="form-group col-md-3 mt-2 mb-2">-->
<!--                <label>{{ $t('customer_transactions_report.interval_type') }}</label>-->
<!--                <select name="" id="period" v-model="filters.period" @input="changeIntervalType" class="custom-select">-->
<!--                  <option v-for="row in period_date" :value="row" :key="row">{{ $t(row) }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="form-group col-md-3 mt-2 mb-2">-->
<!--                <label>{{ $t('customer_transactions_report.interval_no') }}</label>-->
<!--                <select name="" id="range" v-model="filters.range" class="custom-select">-->
<!--                  <option v-for="row in period_counter" :value="row" :key="row">{{ row }}</option>-->
<!--                </select>-->
<!--              </div>-->
<!--              <div class="col-md-6 mt-2 mb-2 pt-10">-->
<!--                <div class="d-flex justify-space-between">-->
<!--                  <b-form-radio id="current_date" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="day">-->
<!--                    {{$t('customer_transactions_report.current_date')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_week" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="week">-->
<!--                    {{$t('customer_transactions_report.current_week')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_month" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="month">-->
<!--                    {{$t('customer_transactions_report.current_month')}}-->
<!--                  </b-form-radio>-->
<!--                  <b-form-radio id="current_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="year">-->
<!--                    {{$t('customer_transactions_report.current_year')}}-->
<!--                  </b-form-radio>-->
<!--&lt;!&ndash;                  <b-form-radio id="financial_year" v-model="filters.period" @input="changeIntervalType" class="w-100" name="period" value="financial_year">&ndash;&gt;-->
<!--&lt;!&ndash;                    {{$t('customer_transactions_report.financial_year')}}&ndash;&gt;-->
<!--&lt;!&ndash;                  </b-form-radio>&ndash;&gt;-->
<!--                  <b-form-checkbox id="financial_year" v-model="filters.financial_year" class="w-100"  name="financial_year" :value="1" :unchecked-value="0">-->
<!--                    {{$t('customer_transactions_report.financial_year')}}-->
<!--                  </b-form-checkbox>-->
<!--                </div>-->


<!--              </div>-->
              <div class="col-md-6 mt-2 mb-2 pt-10">
                <div>
                  <b-button-group>
                    <b-button  @click="changeIntervalType('current_day')" :variant="filters.period=='current_day'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_date')}}</b-button>
                    <b-button  @click="changeIntervalType('current_month')" :variant="filters.period=='current_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_month')}}</b-button>
                    <b-button  @click="changeIntervalType('current_year')" :variant="filters.period=='current_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.current_year')}}</b-button>
                    <b-button  @click="changeIntervalType('financial_year')" :variant="filters.period=='financial_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>
                    <!--                    <b-button  @click="changeFinancialYear" :variant="filters.financial_year==1? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.financial_year')}}</b-button>-->
                  </b-button-group>

                </div>
                <div class="mt-5">
                  <b-button-group>
                    <b-button  @click="changeIntervalType('yesterday')" :variant="filters.period=='yesterday'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.yesterday')}}</b-button>
                    <b-button  @click="changeIntervalType('last_month')" :variant="filters.period=='last_month'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_month')}}</b-button>
                    <b-button  @click="changeIntervalType('last_year')" :variant="filters.period=='last_year'? 'primary' : 'outline-primary'">{{$t('customer_transactions_report.last_year')}}</b-button>
                  </b-button-group>

                </div>
              </div>
              <div class="form-group d-inline-flex col-md-6 mt-10">
                <button id="m_search" class="btn btn-primary mt-auto mr-2" type="button" @click="doFilter">
                  {{ $t('display_report') }}
                </button>
                <button id="resetFilter" class="btn btn-danger mt-auto mr-2" type="button" @click="resetFilter">
                  <i class="fas fa-trash-restore"></i> {{ $t('reset_search') }}
                </button>
              </div>
            </div>
          </div>
        </b-card-body>
      </b-collapse>
    </b-card>

    <div class="card card-custom">
      <div class="card-body">
        <b-tabs content-class="mt-3">

          <b-tab active :title="$t('details')">
            <div class="mt-3">
              <div class="row mb-10">
                <div id="DetailsTableTitle" class="col-md-12 mt-10">
                  <h4 class="text-center">{{ $t('MENU.bonds_and_payments_details_report') }}</h4>
                  <h5 v-if="filters.from_date && filters.to_date" class="text-center">{{ $t('from') }}
                    {{ filters.from_date }} {{ $t('to') }} {{ filters.to_date }}</h5>
                </div>
              </div>
              <div class="row mb-10">
                <div class="col-12">
                  <div class="table-responsive">
                    <div class="row justify-content-end p-4">
                      <b-dropdown :text="$t('export')" class="mr-2 ml-2" right>
<!--                        <download-excel-->
<!--                            :fetch="salesInvoiceDetailsExport"-->
<!--                            :fields="json_fields_details"-->
<!--                            :name="$t('MENU.bonds_and_payments_details_report')+'.xls'"-->
<!--                            class="dropdown-item">-->
<!--                          <i class="la la-file-excel"></i>{{ $t('excel') }}-->
<!--                        </download-excel>-->
                        <button class="dropdown-item" @click="convertTableToExcelReport('DetailsTable')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('excel') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printData('DetailsTable')">
                          <span><i class="fa fa-print"></i> <span>{{ $t('print') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="printPDFData('DetailsTable', 'pdf')">
                          <span><i class="fa fa-file-pdf"></i> <span>{{ $t('export_pdf') }}</span></span>
                        </button>
                        <button class="dropdown-item" @click="getAllDataForExport('sectionForExport', 'excel')">
                          <span><i class="fa fa-file-excel"></i> <span>{{ $t('all_data') }}</span></span>
                        </button>
                      </b-dropdown>
                    </div>
                    <table id="DetailsTable" class="table table-bordered">
                      <thead>
                      <tr>
                        <th>#</th>
                        <th>{{ $t('bonds_and_payments_details_report.id') }}</th>
                        <th>{{ $t('bonds_and_payments_details_report.code') }}</th>
                        <th>{{ $t('bonds_and_payments_details_report.document') }}</th>
                        <th>{{ $t('bonds_and_payments_details_report.customer_name') }}</th>
                        <th>{{ $t('bonds_and_payments_details_report.branch_name') }}</th>
<!--                        <th>{{ $t('bonds_and_payments_details_report.inventory_name') }}</th>-->
                        <th>{{ $t('bonds_and_payments_details_report.payment_method_name') }}</th>
                        <th>{{ $t('bonds_and_payments_details_report.amount') }}</th>
                      </tr>
                      </thead>
                      <tbody>

                      <template v-for="(row, index) in data_report_details">
                        <tr>
                          <td>{{ index + 1 }}</td>
                          <td>{{ row.code? row.code : row.id }}</td>
<!--                          <td>{{ row.id }}</td>-->
<!--                          <td>{{ row.code }}</td>-->
                          <td colspan="7" style="text-align: start !important;">{{ row.model }}</td>
<!--                          <td>{{ row.customer_name }}</td>-->
<!--                          <td>{{ row.branch_name }}</td>-->
<!--                          <td>{{ row.inventory_name }}</td>-->
<!--                          <td>{{ row.payment_method_name }}</td>-->
                        </tr>
                        <tr v-for="(row2, index2) in row.invoices" :key="'axnnn'+getRandom()+index2+index">
                          <td></td>
                          <td>{{ row2.id }}</td>
                          <td>{{ row2.code }}</td>
                          <td>{{ row2.document }}</td>
                          <td>{{ row2.customer_name }}</td>
                          <td>{{ row.branch_name }}</td>
<!--                          <td>{{ row.inventory_name }}</td>-->
                          <td>{{ row.payment_method_name }}</td>
                          <td>{{ row2.amount }}</td>
                        </tr>
                      </template>

                      </tbody>
                      <tfoot>
                      <tr>
                        <td colspan="7"><b>{{ $t('bonds_and_payments_details_report.total') }}</b></td>
                        <th>{{ details_total_sum }}</th>
                      </tr>
                      </tfoot>
                    </table>
                  </div>

                </div>
              </div>
            </div>
          </b-tab>
<!--          <template v-if="isSystemUser" #tabs-end>-->
<!--            <li class="nav-item  d-flex my-auto">-->
<!--              <select id="" v-model="filters.filter_by" class="custom-select border-0 pt-0 pb-0 h-auto shadow-none"-->
<!--                      name="">-->
<!--                <option :value="null">{{ $t('select_filter') }}</option>-->
<!--                <option value="daily">{{ $t('daily') }}</option>-->
<!--                <option value="weekly">{{ $t('weekly') }}</option>-->
<!--                <option value="monthly">{{ $t('monthly') }}</option>-->
<!--                <option value="yearly">{{ $t('yearly') }}</option>-->
<!--                <option value="customer">{{ $t('customer') }}</option>-->
<!--                <option value="employee">{{ $t('employee') }}</option>-->
<!--                <option value="payment_method">{{ $t('payment_method') }}</option>-->
<!--              </select>-->

<!--            </li>-->
<!--          </template>-->
          <!-- <b-tab> -->
          <!-- </b-tab> -->
        </b-tabs>
        <div v-if="page" class="col-12 text-center">
          <button class="btn btn-warning" @click="loadMore">
            <p class="mb-0">
              <b-icon font-scale="1" icon="arrow-counterclockwise"></b-icon>
              {{ $t('load_more') }}
            </p>
          </button>
        </div>
      </div>
    </div>
    <div class="export-container">
      <div class="section-for-export">
        <table id="sectionForExport" class="table table-bordered">
          <thead>
          <tr>
            <th>#</th>
            <th>{{ $t('bonds_and_payments_details_report.id') }}</th>
            <th>{{ $t('bonds_and_payments_details_report.code') }}</th>
            <th>{{ $t('bonds_and_payments_details_report.document') }}</th>
            <th>{{ $t('bonds_and_payments_details_report.customer_name') }}</th>
            <th>{{ $t('bonds_and_payments_details_report.branch_name') }}</th>
            <!--                        <th>{{ $t('bonds_and_payments_details_report.inventory_name') }}</th>-->
            <th>{{ $t('bonds_and_payments_details_report.payment_method_name') }}</th>
            <th>{{ $t('bonds_and_payments_details_report.amount') }}</th>
          </tr>
          </thead>
          <tbody>

          <template v-for="(row, index) in dataList">
            <tr>
              <td>{{ index + 1 }}</td>
              <td>{{ row.code? row.code : row.id }}</td>
              <!--                          <td>{{ row.id }}</td>-->
              <!--                          <td>{{ row.code }}</td>-->
              <td colspan="7" style="text-align: start !important;">{{ row.model }}</td>
              <!--                          <td>{{ row.customer_name }}</td>-->
              <!--                          <td>{{ row.branch_name }}</td>-->
              <!--                          <td>{{ row.inventory_name }}</td>-->
              <!--                          <td>{{ row.payment_method_name }}</td>-->
            </tr>
            <tr v-for="(row2, index2) in row.invoices" :key="'axnnn'+getRandom()+index2+index">
              <td></td>
              <td>{{ row2.id }}</td>
              <td>{{ row2.code }}</td>
              <td>{{ row2.document }}</td>
              <td>{{ row2.customer_name }}</td>
              <td>{{ row.branch_name }}</td>
              <!--                          <td>{{ row.inventory_name }}</td>-->
              <td>{{ row.payment_method_name }}</td>
              <td>{{ row2.amount }}</td>
            </tr>
          </template>

          </tbody>
          <tfoot>
          <tr>
            <td colspan="6"><b>{{ $t('bonds_and_payments_details_report.total') }}</b></td>
            <th>{{ dataList_total_sum }}</th>
          </tr>
          </tfoot>
        </table>

      </div>
    </div>
    <div id="selectorExport" class="selector-export"></div>
    <!--end::customer-->


    <div class="export-container">
      <div class="section-for-export" id="sectionForExportHeader">
        <report-main-header :print-type="'BondsPaymentsDetails'"></report-main-header>
      </div>
    </div>


  </div>
</template>

<script>
import {SET_BREADCRUMB} from "@/core/services/store/breadcrumbs.module";
import ApiService from "@/core/services/api.service";
import exportPDFInnerAction from "@/core/plugins/export-jspdf-inner-action";
import TableToExcel from "@linways/table-to-excel";
import {mapGetters} from "vuex";
import cssStypeForPrintReport from "@/core/config/mix/cssStypeForPrintReport";
import ReportMainHeader from "@/view/content/printing-heads/ReportMainHeader.vue";

export default {
  name: "index-bonds-payments-details-report",
  components: {ReportMainHeader},
  data() {
    return {
      mainRoute: '/reports/sales/client_payment_transactions',
      mainRouteDependency: 'base/dependency',
      invoice_type_list: [
        {key: 'sales_invoice', name: this.$t('invoices_types_trans.sales_invoice'), disabled: false},
        {key: 'general_sales_invoice', name: this.$t('invoices_types_trans.general_sales_invoice'), disabled: false},
        {key: 'pos', name: this.$t('invoices_types_trans.pos'), disabled: false},
        {key: 'sales_refund', name: this.$t('invoices_types_trans.sales_refund'), disabled: false},
        {key: 'sales_credit', name: this.$t('invoices_types_trans.sales_credit'), disabled: false},
        {key: 'sales_debit', name: this.$t('invoices_types_trans.sales_debit'), disabled: false},
        {key: 'general_sales_refund', name: this.$t('invoices_types_trans.general_sales_refund'), disabled: false},
        {key: 'general_sales_credit', name: this.$t('invoices_types_trans.general_sales_credit'), disabled: false},
        {key: 'general_sales_debit', name: this.$t('invoices_types_trans.general_sales_debit'), disabled: false},
        {key: 'sales_payment', name: this.$t('invoices_types_trans.sales_payment'), disabled: false},
        {key: 'expenses_income_transaction', name: this.$t('invoices_types_trans.expenses_income_transaction'), disabled: false},
      ],
      class_list: [
        {key: 1, name: this.$t('expenses_incomes_print.received_voucher')},
        {key: 2, name: this.$t('expenses_incomes_print.expenses')},
        {key: 3, name: this.$t('paymentss')},
    ],
      currency_name: null,
      dataList: [],
      dataList_total_sum: 0,
      line_invoice_details: [],
      data_report_details: [],
      details_total_sum: null,
      employees: [],
      employee: null,
      data_report_summary: [],
      summary_total_sum: null,
      branches: [],
      inventories: [],
      branches_ids: [],
      inventories_ids: [],
      branch_default: null,
      filters: {
        financial_year: 0,
        customers: [],
        branches: [],
        inventories: [],
        is_all_branches: 1,
        from_date: null,
        to_date: null,
        user_id: null,
        payment_method: null,
        collected_user_id: null,
        filter_by: 'payments',
        employee_id: null,
        invoice_type: null,
        code: null,
        period: null,
        f_time_period: 0,
        class: null,
        range: null,
      },
      period_counter: [],
      period_date: ['day','week','month','year'],
      period_numbers: {
        day: 31,
        week: 4,
        month: 12,
        year: 10,
      },
      inner_period: null,
      data: [],
      customers: [],
      users: [],
      payment_method_list: [],
      isPeriod: false,
      periodLabel: 'label',
      customer: [],
      user: null,
      collected_by: null,
      tab: this.$route.query.tab ? this.$route.query.tab : 'summery',
      json_meta: [
        [{
          key: "charset",
          value: "utf-8",
        },],
      ],
      page: 0,

    }
  },
  watch: {
    employee: function (val) {
      if (val) {
        this.filters.employee_id = val.id;
      } else {
        this.filters.employee_id = null;
      }
    },
    // "filters.filter_by": function (val) {
    //   if (val)
    //     this.doFilter();
    //   switch (val) {
    //     case 'daily':
    //       this.isPeriod = true;
    //       this.periodLabel = 'day';
    //       break;
    //     case 'weekly':
    //       this.isPeriod = true;
    //       this.periodLabel = 'week';
    //       break;
    //     case 'monthly':
    //       this.isPeriod = true;
    //       this.periodLabel = 'month';
    //       break;
    //     case 'yearly':
    //       this.isPeriod = true;
    //       this.periodLabel = 'year';
    //       break;
    //     case 'customer':
    //       this.isPeriod = true;
    //       this.periodLabel = 'customer';
    //       break;
    //     case 'employee':
    //       this.isPeriod = true;
    //       this.periodLabel = 'employee';
    //       break;
    //     case 'payment_method':
    //       this.isPeriod = true;
    //       this.periodLabel = 'payment_method';
    //       break;
    //
    //   }
    // },

    user: function (val) {
      if (val) {
        this.filters.user_id = val.id;
      } else {
        this.filters.user_id = null;
      }
    },
    collected_by: function (val) {
      if (val) {
        this.filters.collected_user_id = val.id;
      } else {
        this.filters.collected_user_id = null;
      }
    },
  },
  computed: {

      ...mapGetters(["currentUserPersonalInfo"]),
      isSystemUser: function () {
        return this.currentUserPersonalInfo.is_system_user
      },

    json_fields: function () {
      let fields = {};

      fields[this.$t('bonds_and_payments_details_report.' + this.periodLabel)] = 'label';
      fields[this.$t('bonds_and_payments_details_report.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },

    json_fields_details: function () {
      let fields = {};
      fields[this.$t('bonds_and_payments_details_report.id')] = 'label';
      fields[this.$t('bonds_and_payments_details_report.invoice_number')] = 'invoice_code';
      fields[this.$t('bonds_and_payments_details_report.date')] = 'payment_date';
      fields[this.$t('bonds_and_payments_details_report.customer')] = 'customer_name';
      fields[this.$t('bonds_and_payments_details_report.employee')] = 'added_by';
      fields[this.$t('bonds_and_payments_details_report.payment_method')] = 'payment_method_name';
      fields[this.$t('bonds_and_payments_details_report.ref_no')] = 'ref_no';
      fields[this.$t('revenue_invoices.total') + ` (${this.currency_name})`] = 'total';

      return fields;
    },


  },

  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{
      title: this.$t("MENU.sales_reports"),
      route: '/reports/sales-reports-links'
    }, {title: this.$t("MENU.bonds_and_payments_details_report")}]);

    // this.filters.filter_by = this.$route.query.filter ? this.$route.query.filter : 'daily';


  },
  created() {
    this.getCustomers();
    this.getUsers();
    this.getPaymentMethodList();
    this.getAuthUser();

    this.getBranches();
    this.getInventories();
  },
  methods: {

    ...cssStypeForPrintReport,
    changeFinancialYear(){
      if (this.filters.financial_year == 1)
        this.filters.financial_year = 0;
      else
        this.filters.financial_year = 1;
    },
    changeIntervalType(action_type, range = null){
      if (this.filters.period == action_type){
        this.filters.period = null;
        this.filters.f_time_period = 0;
      }else{
        this.filters.period = action_type;
        this.filters.f_time_period = 1;
      }

      // this.filters.range = range;
      // if (range == 1){
      //   this.inner_period = 'last_'+action_type;
      //   if (action_type == 'year')
      //     this.filters.range = this.$moment().subtract(1, 'year').year();
      // }else{
      //   this.inner_period = action_type;
      // }


      // this.period_counter = [];
      // if (this.filters.period){
      //   let _counter = this.period_numbers[this.filters.period];
      //   if (this.filters.period == 'year') {
      //     let _current_year = new Date().getFullYear();
      //     this.period_counter.push(parseInt(_current_year));
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(parseInt(_current_year) - cc);
      //     }
      //   }else {
      //     for (let cc = 1; cc <= _counter; cc++){
      //       this.period_counter.push(cc);
      //     }
      //   }
      //
      // }else {
      //   this.period_counter = [];
      // }

    },

    getIds(type) {
      if (type === 'branches') {
        this.filters.branches = this.branches_ids.map((row) => row.id);
      }
      if (type === 'inventories') {
        this.filters.inventories = this.inventories_ids.map((row) => row.id);
      }
    },
    getBranches() {
      ApiService.get(this.mainRouteDependency + "/branches").then((response) => {
        this.branches = response.data.data;
      });
    },
    setCustomers(val) {
      if (val) {
        this.filters.customers = val.map((row) => row.id);
      } else {
        this.filters.customers = null;
      }
    },
    salesInvoiceExport() {
      this.setReportLog('excel', 'bonds and payments details report');
      let _footer = {
        label: this.$t('bonds_and_payments_details_report.total') + ` (${this.currency_name})`,
        total: this.summary_total_sum
      };
      let _data = [...this.data_report_summary];
      _data.push(_footer);
      return _data;

    },

    getRandom() {
      return Math.floor(Math.random() * 10000);
    },

    doFilter() {
      this.page = 1;
      this.getReportInvoiceDetails();

    },
    resetFilter() {
      this.filters.customers = null;
      this.filters.from_date = null;
      this.filters.to_date = null;
      this.filters.user_id = null;
      this.filters.payment_method = null;
      this.filters.collected_user_id = null;
      // this.filters.filter_by = null;
      this.customer = null;
      this.user = null;
      this.collected_by = null;
      this.employee = null;
      this.branches_ids = [];
      this.filters.branches = [];
      this.filters.employee_id = null;
      this.filters.invoice_type = null;
      this.filters.code = null;
      this.filters.period = null;
      this.filters.f_time_period = 0;
      this.filters.class = null;
      this.filters.range = null;
      this.filters.financial_year = 0;
      this.filters.is_all_branches = 1;
      this.filters.branches = [];
      this.inner_period = null;
      this.inventories_ids = [];
      this.filters.inventories = [];
      this.doFilter();

    },
    async getCustomers(filter) {
      if (filter && filter.length >= 3)
        await ApiService.get(this.mainRouteDependency + "/customers", {params: {filter: filter}}).then((response) => {
          this.customers = response.data.data;
        });
    },
    getUsers() {
        ApiService.get(this.mainRouteDependency + "/users").then((response) => {
          this.users = response.data.data;
        });
    },
    getAuthUser() {
      ApiService.get(`/get_auth`).then((response) => {
        this.currency_name = response.data.data.currency_name;
      });
    },
    getEmployees(filter) {
      if (filter && filter.length >= 3)
        ApiService.get(this.mainRouteDependency + "/employees", {params: {name: filter}}).then((response) => {
          this.employees = response.data.data;
        });
    },
    getPaymentMethodList() {
      ApiService.get(this.mainRouteDependency + "/payments_methods").then((response) => {
        this.payment_method_list = response.data.data;
      });
    },
    getInventories() {
      ApiService.get(this.mainRouteDependency + `/inventories`).then((response) => {
        this.inventories = response.data.data;
      });
    },
    loadMore() {
      this.page = this.page ? (this.page + 1) : 1;
      this.getReportInvoiceDetails();
    },
    /*****
     * details
     */


    getReportInvoiceDetails() {
      let _limit = (this.page ? this.page : 1) * 15
      ApiService.get(`${this.mainRoute}`, {
        params: {
          ...this.filters,
          limit: _limit
        }
      }).then((response) => {
        this.data_report_details = response.data.data.data.items;
        this.details_total_sum = response.data.data.data.total ? response.data.data.data.total : 0;
      });
    },

    getAllDataForExport(tableId, _export) {
      ApiService.get(this.mainRoute, {params: {...this.filters, limit: 999999999999}}).then((response) => {
        this.dataList = response.data.data.data.items;
        this.dataList_total_sum = response.data.data.data.total ? response.data.data.data.total : 0;
        // if (_export == 'pdf' || _export == 'print')
        //   this.printData(tableId, _export);
        // if (_export == 'excel')
          this.convertTableToExcelReport(tableId);
      });
    },

    /**
     * get for summary tab
     */

    printData(tableId) {
      this.setReportLog('pdf', 'bonds and payments details report');

      let divToPrint = document.getElementById(tableId);
      let divTitleToPrint = document.getElementById('sectionForExportHeader');
      let newWin = window.open("");
      newWin.document.write('<meta name="viewport" content="width=device-width, initial-scale=1, shrink-to-fit=no">');
      newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>");
      newWin.document.write('<style>');
      newWin.document.write(this.getCssStyleForPrintReport());
      newWin.document.write('</style>');
      newWin.document.write(divTitleToPrint.outerHTML + divToPrint.outerHTML);
      setTimeout(() => {
        newWin.print();
        // newWin.close();
      }, 100)
    },

    printPDFData(tableId, type) {
      this.setReportLog('pdf', 'bonds and payments details report');

      // let divToPrint = document.getElementById(tableId);
      // let divTitleToPrint = document.getElementById(tableId + 'Title');
      // let newWin = window.open("");
      // newWin.document.write("<link rel='stylesheet' href='https://cdn.jsdelivr.net/npm/bootstrap@4.0.0/dist/css/bootstrap.min.css'>" + divTitleToPrint.outerHTML + divToPrint.outerHTML);
      // setTimeout(() => {
      //     newWin.print();
      //     newWin.close();
      // }, 100)
      exportPDFInnerAction(tableId, type, this.$t('MENU.bonds_and_payments_details_report'), this.$t('MENU.bonds_and_payments_details_report'));
    },

    convertTableToExcelReport(tableId) {
      this.setReportLog('excel', 'bonds and payments details report');
      setTimeout(() => {
        TableToExcel.convert(document.getElementById(tableId), {
          name: this.$t('MENU.bonds_and_payments_details_report') + '.xlsx',
          sheet: {
            name: 'Sheet 1'
          }
        });
      }, 100)
    },
    /**
     * register log
     */
    setReportLog(key, description) {
      ApiService.post("reports/system_activity_log/log_type", {
        key: key,
        description: description,
      });
    }

  },
};
</script>
<style>
.html2pdf__overlay {
  opacity: 0 !important;
  z-index: -1000;

}

.selector-export {
  opacity: 0 !important;
  z-index: -1000 !important;
  width: 0.0001px !important;
  height: 0.0001px !important;
  overflow: auto;
}
</style>
